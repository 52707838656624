.conatiner_p-1-addCard-Chapter_1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.conatiner_p-1-addCard-Chapter_1 button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.conatiner_p-1-addCard-Chapter_1 button:hover {
    background-color: #0056b3;
}

.conatiner_p-2-cards-Chapter_1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.conatiner_s-2-cards-Chapter_1 {
    width: 30%;
    margin: 34px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    background-color: #fff;
    transition: box-shadow 0.3s ease;
}

.conatiner_s-2-cards-Chapter_1:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 10px 0;
    font-size: 1rem;
}

button div {
    padding: 10px;
    background-color: #e7e7e7;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

button:hover div {
    background-color: #d1d1d1;
}


.conatiner_p-DiveIn_Buttons-Chapter_1{
    display: flex;
    flex-direction: row;
}
.conatiner_s-1-DiveIn_Buttons-Chapter_1{
    display: flex;
    flex-direction: row;
}