.mainContainer-TripPlanning {
  width: 100%;
  display: flex;
  flex-direction: row; /* Set the direction to row */
}

.container1-TripPlanning {
  width: 70%;
  display: flex;
  flex-direction: column; /* Set the direction to column */
}

.container2-TripPlanning {
  width: 30%;
}

/* Add more specific styling for each container */
.randomPlaces-MainContainer-TripPlanning {
  /* Styles for Random Places container */
}

.destinationName-MainContainer-TripPlanning {
  /* Styles for Destination Name container */
}

.map-MainContainer-TripPlanning {
  /* Styles for Map container */
}

/* Add styles for other containers... */


.savedNotes-Editor{
  margin-top: 4%;
  border-radius: 1px orange dotted;
  border-radius: 6px;
  padding: 1%;
}


/* customFonts.css */
@import url('https://fonts.googleapis.com/css2?family=Arial:wght@400;700&family=Open+Sans:wght@400;700&family:Roboto:wght@400;700&family=Lato:wght@400;700&family=Merriweather:wght@400;700&family=Montserrat:wght@400;700&display=swap');


.ql-font-arial { font-family: 'Arial', sans-serif; }
.ql-font-comic-sans { font-family: 'Comic Sans MS', cursive; }
.ql-font-courier-new { font-family: 'Courier New', monospace; }
.ql-font-georgia { font-family: 'Georgia', serif; }
.ql-font-helvetica { font-family: 'Helvetica', sans-serif; }
.ql-font-lucida { font-family: 'Lucida Sans', sans-serif; }
/* Font classes for your editor */
.ql-font-open-sans { font-family: 'Open Sans', sans-serif; }  /* Clean, modern sans-serif */
.ql-font-roboto { font-family: 'Roboto', sans-serif; }        /* Versatile and popular */
.ql-font-lato { font-family: 'Lato', sans-serif; }            /* Clean and geometric */
.ql-font-merriweather { font-family: 'Merriweather', serif; }  /* Elegant serif */
.ql-font-montserrat { font-family: 'Montserrat', sans-serif; } /* Modern and friendly */
