$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//

/* Reset default margins and paddings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.editor-container {

  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  box-shadow: 0px 8px 16px 0px rgba(255, 0, 0, 0.2);
  margin: 30px 10px 10px 10px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .editor-toolbar {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
   
  }

  .dropdown {
    position: relative;
    display: inline-block;

    .dropbtn {
      background-color: #f5f5f5;
      color: white;
      border-radius: 8px;
      padding: 8px 12px 8px 12px;
      margin: 12px 8px 12px 8px;
      font-family: $font-6;
      color: black;
      font-size: 16px;
      border: none;
      cursor: pointer;

      &:hover{
        background: rgb(255, 244, 217);
      }
      &:active{
        background: rgb(255, 175, 145);
      }

    }

    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #f9f9f9;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 1;

      button {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;

        &:hover {
          background-color: #f1f1f1;
        }
      }

      .is-active {
        background-color: #ddd;
      }
    }

    &.active .dropdown-content {
      display: block;
    }
  }

  .editor-character-count {
    margin-top: 10px;
    font-size: 14px;
    color: #333;
  }
}


//!conatiner 1
//*Buttons
/* Container 1 - Buttons */
.container_p-buttons-TestingLexical {
  width: 100%;
  margin: 30px 20px;
}

.conatiner_p-buttons-TestingLexical {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 4px;
}

.button-1-buttons-TestingLexical {
  background-color: #f5f5f5;
  color: black;
  border-radius: 8px;
  padding: 8px 12px;
  margin: 12px 8px;
  font-family: 'Josefin Sans'; /* Using font-6 as button font */
  font-size: 16px;
  border: none;
  cursor: pointer;

  &:hover {
    background: rgb(255, 244, 217);
  }
  &:active {
    background: rgb(255, 175, 145);
  }
}

.container_p-buttons-TestingLexical .dropdown-content button:hover {
  background-color: #efefef;
  width: 100%;
  border: none;
}

.container_p-buttons-TestingLexical .dropdown:hover .dropdown-content {
  display: block;
  background: none;
  border-radius: 12px;
}

.button-3-dropdown-TestingLexical {
  background: none;
  border: none;
  margin: 4px 0;
  padding: 4px;
  white-space: nowrap;

  &:hover {
    background: whitesmoke;
    border-radius: 8px;
  }
  &:active {
    background: rgb(255, 227, 227);
  }
}


.container_p-font-TestingLexical{
  position: absolute;
  background: rgb(255, 255, 255);
  box-shadow: rgb(203, 203, 200) 0px 0px 5px;
  height: 160px;
  width: 160px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: -20%;
  margin-left: -10px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  z-index: 10000;
}
.container_p-heading-TestingLexical{
  position: absolute;
  background: rgb(255, 255, 255);
  box-shadow: rgb(203, 203, 200) 0px 0px 5px;
  // height: 160px;
  width: 60px;
  // overflow-y: scroll;
  // overflow-x: hidden;
  margin-top: -20%;
  // margin-left: -10px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  z-index: 10000;
}
.container_p-3-TestingLexical{
  position: absolute;
  background: rgb(255, 255, 255);
  box-shadow: rgb(203, 203, 200) 0px 0px 5px;
  // height: 160px;
  width: fit-content;
  // overflow-y: scroll;
  // overflow-x: hidden;
  margin-top: -20%;
  // margin-left: -10px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  z-index: 10000;
}


//! Conatiner 2 - Word Count
.container_p-WordCount-TestingLexical{
  width: 88%;
  // border: 1px solid brown;
}
.container_s-WordCount-TestingLexical{
  display: flex;
  color: rgb(142, 139, 139);
  font-family: $font-heading-1;
  font-size: 14px;
  margin-bottom: 4px;
}

//!Conatiner 3
.conatiner_p-editor-TestingLexical{
  // display: flex;
  text-align: start;
  text-align: start;
  box-shadow: rgb(203, 203, 200) 0px 0px 5px;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  margin-top: 10px;
  // border: 1px solid #ccc;
  padding: 10px;
  min-height: 200px;
  // font-family: $font-4;
  color: rgb(0, 0, 0);
  font-size: 16px;
  width: 90%;
}

.conatiner_p-editor-TestingLexical .ProseMirror{
  // border: rgb(188, 189, 187) 1px solid;
  background: white;
  font-family: $font-7;
  color: rgb(0, 0, 0);
  min-height: 200px;
  padding: 5px; /* Add padding for better appearance */
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
  outline: none; /* Remove the default focus outline */
}


.container_p-editor-TestingLexical {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-top: 20px;
}

.container_p-editor-TestingLexical h1 {
  font-size: 2em;
  margin-bottom: 0.5em;
  color: #333;
}

.container_p-editor-TestingLexical h2 {
  font-size: 1.75em;
  margin-bottom: 0.5em;
  color: #444;
}

.container_p-editor-TestingLexical h3 {
  font-size: 1.5em;
  margin-bottom: 0.5em;
  color: #555;
}

.container_p-editor-TestingLexical ul {
  list-style-type: disc;
  padding-left: 40px;
}

.container_p-editor-TestingLexical ol {
  list-style-type: decimal;
  padding-left: 40px;
}

.container_p-editor-TestingLexical li {
  margin-bottom: 0.5em;
}

.container_p-editor-TestingLexical blockquote {
  border-left: 4px solid #ddd;
  padding-left: 20px;
  margin-left: 0;
  color: #666;
  font-style: italic;
}

.container_p-editor-TestingLexical strong {
  font-weight: bold;
}

.container_p-editor-TestingLexical em {
  font-style: italic;
}

.container_p-editor-TestingLexical u {
  text-decoration: underline;
}

.container_p-editor-TestingLexical del {
  text-decoration: line-through;
}

.container_p-editor-TestingLexical .task-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}

.container_p-editor-TestingLexical .task-list-item input {
  margin-right: 10px;
}

.container_p-editor-TestingLexical .task-list-item.checked {
  text-decoration: line-through;
  color: #aaa;
}

.container_p-editor-TestingLexical .highlight {
  background-color: yellow;
}

.container_p-editor-TestingLexical .text-center {
  text-align: center;
}

.container_p-editor-TestingLexical .text-right {
  text-align: right;
}

/* Additional Tiptap Specific Styles */
.ProseMirror {
  outline: none;
}

.ProseMirror p {
  margin: 0 0 1em;
  color: rgb(81, 78, 78);
  font-family: $font-6;
}

.ProseMirror ul, .ProseMirror ol {
  padding-left: 30px;
}

.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 4px solid #ccc;
  margin-left: 0;
}

.ProseMirror pre {
  background: #f4f4f4;
  padding: 1rem;
  border-radius: 5px;
  overflow-x: auto;
}

.ProseMirror hr {
  border: none;
  border-top: 1px solid #ccc;
  margin: 1em 0;
}

.ProseMirror img {
  max-width: 100%;
  height: auto;
}


