.overlay-Chapter_Popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Black background with opacity */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the overlay is on top of everything */
}

.popup-container-Chapter_Popup {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 100%;
}

.popup-header-Chapter_Popup {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
}

.popup-header-Chapter_Popup button {
    padding: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.popup-header-Chapter_Popup button:hover {
    background-color: #0056b3;
}

.popup-mainContainer-Chapter_Popup {
    display: flex;
    flex-direction: column;
}

.popup-mainContainer-Chapter_Popup input,
.popup-mainContainer-Chapter_Popup textarea {
    // width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
}



.container_t-closeSaveButtons-ChapterPopup{
    display: flex;
    flex-direction: row;
}
.container_t-closeSaveButtons-ChapterPopup button{
    margin-right: 10px;
}