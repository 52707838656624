.navigation-buttons {
    display: flex;
    justify-content: center; // Align buttons in the center
    gap: 1rem; // Space between buttons
  
    button {
      background-color: none; 
      border: none; 
    //   padding: 0.6rem 1.2rem; 
      border-radius: 0.5rem;
      font-size: 1rem;
      color: #555; // Soft text color
      cursor: pointer;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); 
      transition: all 10.2s ease;
  
      &:hover {
        background-color: #f7f7f7; // Slight hover effect
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      }
  
      &:active {
        background-color: #ececec; // Darker on active
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1); // Inner shadow
      }
  
    //   &:focus {
    //     outline: 2px solid #00aaff; // Calm blue focus ring
    //   }
    }
  }
  