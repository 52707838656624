/* Test.css */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup {
    background: white;
    border-radius: 8px;
    padding: 20px;
    /* height: 80%;
    width: 80%; */
    height: 100%;
    width: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    overflow: scroll;
}

.popup-header {
    font-size: 1.2rem;
    margin-bottom: 10px;
    font-weight: bold;
}

.popup-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.popup-form label {
    font-size: 0.9rem;
    font-weight: 500;
}

.popup-form input,
.popup-form select {
    padding: 8px;
    font-size: 0.9rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.popup-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.popup-actions button {
    padding: 8px 15px;
    font-size: 0.9rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.popup-actions .cancel {
    background: #f44336;
    color: white;
}

.popup-actions .add {
    background: #4caf50;
    color: white;
}



.delete-button {
    background-color: red;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    margin-top: 10px;
}

.delete-button:hover {
    background-color: darkred;
}



.view-local {
    background-color: green;
    color: white;
}

.remove-local-view {
    background-color: red;
    color: white;
}

.delete-button {
    background-color: red;
    color: white;
}


.popup-tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
}
.popup-tabs button {
    padding: 8px 16px;
    cursor: pointer;
    border: none;
    background-color: #ddd;
    border-radius: 5px;
}
.popup-tabs button.active {
    background-color: #007bff;
    color: white;
}
  

/* .starry-background {
    width: 100%;
    height: 100vh;
    background: radial-gradient(circle, #ffffff, #ffffff);
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
}
  
.starry-background::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.6;
}
  

.circle-node {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.6);
    color: rgb(0, 0, 0);
    font-weight: bold;
    text-align: center;
    border: 2px solid rgb(162, 162, 162);
}
  
.react-flow__controls {
    background: none;
}
  

.react-flow__edge-path {
    stroke: #ad3333;
    stroke-width: 200px;
}
   */





.popup-overlay-MindMap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.popup-MindMap {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.close-button-MindMap {
    background: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.popup-header-MindMap {
    margin-bottom: 1rem;
}

.input-MindMap,
.textarea-MindMap {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
}

.button-container-MindMap {
    margin-bottom: 10px;
}

.save-brain-button-MindMap,
.add-module-button-MindMap,
.add-brain-button-MindMap {
    background: #4caf50;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 4px;
}

.brain-list-MindMap {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
}

.brain-button-MindMap {
    margin: 5px;
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}

.nodes-list-MindMap {
    margin-top: 20px;
}

.node-button-MindMap {
    margin: 5px;
    padding: 10px;
    background: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    white-space: nowrap;
}

.all-modules-button-MindMap {
    margin-top: 10px;
    padding: 10px;
    background: #ddd;
    border-radius: 4px;
    cursor: pointer;
}


/* //! */
.mindmap-options-button-container-MindMap {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 10;
}
.mindmap-options-button-MindMap {
    background-color: orange;
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
}

.mindmap-options-button-MindMap:hover {
    transform: scale(1.1);
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.3);
}

.mindmap-options-button-MindMap:active {
    transform: scale(0.9);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
}







/* //!! */
.popup-overlay-Main-MindMap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.popup-Main-MindMap {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    height: 80%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}




/* ///! */
.mindmap-options-container-MindMap {
    position: relative; /* Ensures positioning relative to this div */
  }
  
  .mindmap-options-button-MindMap {
    background-color: orange;
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .mindmap-options-button-MindMap:hover {
    transform: scale(1.1);
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.3);
  }
  
  .mindmap-options-button-MindMap:active {
    transform: scale(0.9);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
  }
  
  .nodes-list-MindMap {
    position: absolute;
    top: 0;
    left: 60px; /* Positions the floating options to the right of the button */
    background: rgba(255, 165, 0, 0.9); /* Translucent orange background */
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    gap: 10px; /* Adds space between buttons */
    z-index: 1000;
  }
  
  .nodes-list-MindMap button {
    background: white;
    color: orange;
    border: 1px solid orange;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.2s;
  }
  
  .nodes-list-MindMap button:hover {
    background: orange;
    color: white;
    transform: scale(1.05);
  }
  
  .close-options-button-MindMap {
    align-self: flex-end;
    background: red;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
  }
  
  .all-modules-button-MindMap,
  .node-button-MindMap {
    text-align: left;
  }
  