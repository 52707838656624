/* Container */
.container {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  /* Titles */
  .title {
    text-align: center;
    color: #333;
  }
  
  .subTitle {
    color: #555;
  }
  
  /* Buttons */
  .button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 5px;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  .deleteButton {
    background-color: #ff4d4f;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .deleteButton:hover {
    background-color: #cc0000;
  }
  
  /* Popup */
  .popup {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .popup-2 {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    position: fixed;
    top: 4%;
    left: 6%;
    width: 80%;
    /* transform: translate(-50%, -50%); */
    z-index: 1000;
    /* box-shadow: 1 40px 60px rgba(0, 0, 0, 0.1); */
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    height: 90%;
    overflow: scroll;
  }
  
  .buttonContainer {
    display: flex;
    justify-content: space-between;
  }
  
  /* Input Fields */
  .input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  /* Table List */
  .tableList {
    list-style: none;
    padding: 0;
  }
  
  .tableItem {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 5px;
    cursor: pointer;
    background-color: #f9f9f9;
  }
  
  .tableItem:hover {
    background-color: #f1f1f1;
  }
  
  /* Table */
  .tableContainer {
    margin-top: 20px;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .tableHeader {
    background-color: #f5f5f5;
    padding: 10px;
    border: 1px solid #ddd;
  }
  
  .tableCell {
    padding: 10px;
    border: 1px solid #ddd;
  }
  
  /* Add Section */
  .addSection {
    margin-top: 20px;
  }
  
  .rowInput {
    margin-bottom: 10px;
  }
  

/* View Only */
/* View-Only Table Container */
.table-view-only {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 16px;
    text-align: left;
  }
  
  /* View-Only Table Header */
  .tableHeader-view-only {
    background-color: #f8f9fa;
    font-weight: bold;
    border: 1px solid #ddd;
    padding: 10px;
    color: #333;
  }
  
  /* View-Only Table Cell */
  .tableCell-view-only {
    border: 1px solid #ddd;
    padding: 8px;
    color: #555;
  }
  
  /* Alternate Row Styling */
  .table-view-only tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .table-view-only tbody tr:nth-child(odd) {
    background-color: #ffffff;
  }
  
  /* Table Hover Effect */
  .table-view-only tbody tr:hover {
    background-color: #eaf4ff;
  }
  
  /* Table Header Text Alignment */
  .tableHeader-view-only {
    text-align: center;
  }
  
  /* Center Align Data if Needed */
  .tableCell-view-only {
    text-align: center;
  }
  
  /* Responsive Styling */
  @media (max-width: 768px) {
    .table-view-only {
      font-size: 14px;
    }
    .tableHeader-view-only,
    .tableCell-view-only {
      padding: 6px;
    }
  }
  












/* ///// */

.tableItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 5px 0;
    background-color: #f9f9f9;
    cursor: pointer;
  }
  
  .tableDetails {
    flex-grow: 1;
  }
  
  .tableActions {
    display: flex;
    gap: 8px;
  }
  
  .editButton,
  .deleteButton {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .editButton {
    background-color: #007bff;
    color: #fff;
  }
  
  .editButton:hover {
    background-color: #0056b3;
  }
  
  .deleteButton {
    background-color: #dc3545;
    color: #fff;
  }
  
  .deleteButton:hover {
    background-color: #a71d2a;
  }
  