/* General page layout */
.main-entry-page {
    text-align: center;
    font-family: Arial, sans-serif;
    padding: 20px;
}

.header {
    background-color: #4caf50;
    color: white;
    padding: 20px;
    border-radius: 5px;
}

.app-name {
    font-size: 2rem;
    margin: 0;
}

.tagline {
    font-size: 1rem;
    margin-top: 5px;
    font-style: italic;
}

.content {
    margin-top: 40px;
}

.login-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
}

.login-button:hover {
    background-color: #45a049;
}

/* Login popup styling */
.login-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: left;
}

.popup-content h2 {
    margin-top: 0;
    text-align: center;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.submit-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.submit-button:hover {
    background-color: #45a049;
}

.close-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.close-button:hover {
    background-color: #e53935;
}
