/* Container */
.calendar-container {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f1f1f1;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Calendar Title */
.calendar-title {
  text-align: center;
  font-size: 2rem;
  color: #f39c12;
  font-family: 'Arial', sans-serif;
  margin-bottom: 20px;
}

/* Header customization */
.fc-header-toolbar {
  background-color: #ff6f61;
  color: white;
  padding: 12px;
  border-radius: 12px 12px 0 0;
}

.fc-button-primary {
  background-color: #3498db;
  color: white;
  padding: 8px 16px;
  border-radius: 10px;
  font-size: 14px;
}

.fc-button-primary:hover {
  background-color: #2980b9;
}

/* Event customization */
.custom-event {
  background-color: #f39c12;
  color: white;
  border-radius: 10px;
  padding: 10px;
  font-size: 16px;
}

.custom-event:hover {
  background-color: #e67e22;
}

/* Day Header customization */
.custom-day-header {
  font-size: 14px;
  font-weight: bold;
  background-color: #ff6f61;
  text-align: center;
  color: white;
}


.calendar-container {
    text-align: center;
    padding: 20px;
  }
  
  .calendar-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .view-toggle button {
    margin: 5px;
    padding: 8px 15px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .view-toggle button:hover {
    background-color: #0056b3;
  }
  
  .year-view {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 20px;
  }
  
  .month {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    background-color: #f9f9f9;
  }
  
  .month h2 {
    margin: 0;
    font-size: 18px;
    color: #333;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
  }
  



/* Modal container */
.event-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 2rem;
    width: 400px;
    max-width: 90%;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    font-family: Arial, sans-serif;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  /* Modal backdrop */
  .ReactModal__Overlay {
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    transition: opacity 0.3s ease-in-out;
  }
  
  /* Modal header */
  .event-modal h2 {
    margin-top: 0;
    font-size: 1.5rem;
    color: #333;
    text-align: center;
  }
  
  /* Form styles */
  .event-modal form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .event-modal label {
    font-size: 0.9rem;
    color: #555;
  }
  
  .event-modal input[type="text"],
  .event-modal textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .event-modal textarea {
    resize: vertical;
    height: 80px;
  }
  
  /* Checkbox */
  .event-modal input[type="checkbox"] {
    margin-right: 0.5rem;
  }
  
  /* Buttons */
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .modal-buttons button {
    flex: 1;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
  }
  
  .modal-buttons button:nth-child(1) {
    background-color: #4caf50;
    color: #fff;
  }
  
  .modal-buttons button:nth-child(1):hover {
    background-color: #45a049;
  }
  
  .modal-buttons button:nth-child(2) {
    background-color: #f44336;
    color: #fff;
  }
  
  .modal-buttons button:nth-child(2):hover {
    background-color: #e53935;
  }
  
  .modal-buttons button:nth-child(3) {
    background-color: #ccc;
    color: #333;
  }
  
  .modal-buttons button:nth-child(3):hover {
    background-color: #bbb;
  }
  
  /* Animation for modal */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate(-50%, -48%);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  






/* Modal Styles */
.event-modal {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    margin: auto;
  }
  
  .event-modal input, .event-modal textarea {
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .bullet-points {
    margin-top: 15px;
  }
  
  .add-bullet-point {
    background-color: #4CAF50;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .bullet-point {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .bullet-point input {
    margin-right: 10px;
    flex: 1;
  }
  
  .bullet-point button {
    margin-left: 10px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .bullet-point button:nth-child(2) {
    background-color: #FFC107;
  }
  
  .modal-buttons button {
    padding: 8px 12px;
    background-color: #2196F3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .modal-buttons button i {
    margin-right: 5px;
  }
  