.popup-overlay-knowledge {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.popup-knowledge {
    background: white;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 400px;
    z-index: 1000000;
}

.input-id-knowledge,
.input-name-knowledge,
.textarea-description-knowledge {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.textarea-description-knowledge_2 {
  width: 40%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.textarea-description-knowledge {
    resize: none;
    height: 100px;
}

.button-group-knowledge {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.cancel-button-knowledge,
.save-button-knowledge {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
}

.cancel-button-knowledge {
    background: #f8d7da;
    color: #721c24;
}

.save-button-knowledge {
    background: #d4edda;
    color: #155724;
}

.save-button-knowledge:disabled {
    background: #e9ecef;
    color: #6c757d;
    cursor: not-allowed;
}



/* //! */
.knowledge-item-button-knowledge {
    padding: 1rem;
    background-color: #e7f5ff;
    border: 1px solid #cce5ff;
    border-radius: 4px;
    text-align: left;
    cursor: pointer;
    font-size: 1rem;
    transition: all 0.3s ease;
}

.knowledge-item-button-knowledge:hover {
    background-color: #cce5ff;
    border-color: #b8daff;
}

.knowledge-item-button-knowledge:active {
    background-color: #b8daff;
    border-color: #a3c7ee;
}

.knowledge-item-button-knowledge br {
    line-height: 1.5rem;
}
/* Container Styling */
.knowledge-list-container-knowledge {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 1px solid #ddd;
  /* max-height: 60%; */
  overflow-y: auto;
}
  
  /* Button Styling */
  .knowledge-item-button-knowledge {
    padding: 1rem;
    background-color: #e7f5ff;
    border: 1px solid #cce5ff;
    border-radius: 4px;
    text-align: left;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    transition: all 0.3s ease;
  }
  
  .knowledge-item-button-knowledge:hover {
    background-color: #cce5ff;
    border-color: #b8daff;
  }
  
  .knowledge-item-button-knowledge:active {
    background-color: #b8daff;
    border-color: #a3c7ee;
  }
  
  /* Name Styling */
  .knowledge-name-knowledge {
    font-weight: bold;
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 0.3rem;
  }
  
  /* Description Styling */
  .knowledge-description-knowledge {
    font-size: 0.9rem;
    color: #555;
    line-height: 1.4;
  }
  


/* //! */ Add Knowledge
.popup-overlay-content-knowledgeAA {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.popup-content-knowledgeAA {
    background: white;
    border-radius: 8px;
    padding: 20px;
    /* height: 10%; */
    /* width: 100%; */
    z-index: 1000000;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}


.popup-overlay-content-knowledge {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content-knowledge {
    background: white;
    border-radius: 8px;
    padding: 20px;
    height: 100%;
    width: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    overflow-y: auto; /* Vertical scroll when content overflows */
    overflow-x: hidden; /* Prevent horizontal scroll */
  }
  
  /* Custom Scrollbar Styling */
  .popup-content-knowledge::-webkit-scrollbar {
    width: 4px; /* Thin scrollbar */
  }
  
  .popup-content-knowledge::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light background for scrollbar track */
  }
  
  .popup-content-knowledge::-webkit-scrollbar-thumb {
    background: #d1d1d1; /* Light grey scrollbar handle */
    border-radius: 8px; /* Rounded corners for handle */
  }
  
  .popup-content-knowledge::-webkit-scrollbar-thumb:hover {
    background: #bbbbbb; /* Slightly darker on hover */
  }
  