.container_p-Home {
    height: 100vh; 
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
  }
  
  .button-1-Home {
    padding: 15px 30px;
    font-size: 1.5rem;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button-1-Home:hover {
    background-color: #0056b3;
  }
  
  .button-1-Home:active {
    background-color: #003f7f;
  }
  